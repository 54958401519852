<template>
  <v-card class="overlay ma-10" v-if="employee" elevation="10">
    <img src="@/assets/logo-hes-so.svg" class="logo" />
    <div class="content">
      <div class="name">
        <div class="text-uppercase font-weight-bold">
          {{ employee.firstname }} {{ employee.lastname }}
        </div>
      </div>
      <div class="contact mt-2 pr-1">
        <div v-for="(title, index) in employee.titles" :key="`title-${index}`">
          <div v-if="title.config.cards">
            {{ title.longName }}
          </div>
        </div>
        <div v-if="employee.corporateTitle">
          <div v-if="employee.corporateTitle.config.cards">
            {{ employee.corporateTitle.longName }}
          </div>
        </div>
        <div v-for="(role, index) in employee.roles" :key="`role-${index}`">
          <div
            v-if="
              role.config.cards && specialRequest.indexOf(role.longName) > -1
            "
          >
            {{
              role[
                role.longName !== "Maître d'enseignement"
                  ? role.config.labels.cards
                  : "longName"
              ]
            }}<span
              v-if="employee.entities.find((e) => e.level === 'Département')"
              >{{
                ` - ${
                  employee.entities.find((e) => e.level === "Département").level
                } ${
                  employee.entities.find((e) => e.level === "Département").name
                }`
              }}</span
            >
          </div>
          <div
            v-if="
              role.config.cards && specialRequest.indexOf(role.longName) === -1
            "
          >
            {{
              shortNameDisplay.indexOf(role.longName) > -1
                ? role.shortName
                : role[role.config.labels.cards]
            }}
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="bottom-content">
          <div>{{ employee.email }}</div>
          <div>
            <div
              v-if="
                employee.phones.find((p) => p.type === 'landline') &&
                employee.phones.find((p) => p.type === 'landline').config.cards
              "
            >
              <div style="width: 12px; display: inline-block">
                {{
                  employee.phones.find((p) => p.type === "landline").prefix
                    ? employee.phones.find((p) => p.type === "landline").prefix
                    : "T"
                }}
              </div>

              {{ employee.phones.find((p) => p.type === "landline").number }}
            </div>
            <div
              v-if="
                employee.phones.find((p) => p.type === 'cellphone') &&
                employee.phones.find((p) => p.type === 'cellphone').config.cards
              "
            >
              <div style="width: 12px; display: inline-block">
                {{
                  employee.phones.find((p) => p.type === "cellphone").prefix
                    ? employee.phones.find((p) => p.type === "cellphone").prefix
                    : "M"
                }}
              </div>
              {{ employee.phones.find((p) => p.type === "cellphone").number }}
            </div>
            <div
              v-if="
                employee.phones.find((p) => p.type === 'central') &&
                employee.phones.find((p) => p.type === 'central').config.cards
              "
            >
              <div style="width: 12px; display: inline-block">
                {{
                  employee.phones.find((p) => p.type === "central").prefix
                    ? employee.phones.find((p) => p.type === "central").prefix
                    : "C"
                }}
              </div>
              {{ employee.phones.find((p) => p.type === "central").number }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  props: ["employee", "specialRequest", "shortNameDisplay"],
};
</script>

<style scoped>
.name {
  color: #e1251b;
}
.contact {
  position: absolute;
  line-height: initial;
  word-break: keep-all;
}
.bottom {
  position: relative;
  min-height: 160px;
  width: 220px;
  word-break: keep-all;
}
.bottom-content {
  position: absolute;
  line-height: initial;
  bottom: 0;
  left: 0;
}
div.business-card {
  width: 95mm;
  height: 55mm;
  border-top: 1px solid;
  border-bottom: 1px solid;
  margin-bottom: 40px;
  position: relative;
  padding: 0mm 5mm;
}
div.business-card:after {
  content: "";
  display: block;
  position: absolute;
  top: -16px;
  left: 18px;
  width: 85mm;
  height: 63mm;
  border-left: 1px solid;
  border-right: 1px solid;
}
.business-card {
  display: inline-block;
}
.logo {
  position: absolute;
  top: 170px;
  left: 260px;
  height: 20px;
  display: inline-block;
}
.content {
  display: inline-block;
  position: absolute;
  top: 10px;
  left: 20px;
  font-size: 7pt;
  height: 100%;
  font-family: "CircularXXTT Regular";
  width: 70mm;
}
.overlay {
  line-height: 1;
  position: relative;
  height: 55mm;
  width: 85mm;
  transform: translate(-1mm, -0.75mm);
  z-index: 5;
}
.entities {
  font-family: "CircularXXTT Regular";
  line-height: 20px;
}
.address {
  transform: translate(15px, 75px);
}
.website {
  transform: translate(15px, 75px);
}
</style>