import Vue from "vue";
import VueRouter from "vue-router";
import Informations from "@/views/Informations.vue";
import BusinessCards from "@/views/BusinessCards.vue";
import MailSignatures from "@/views/MailSignatures.vue";
import WhitePages from "@/views/WhitePages.vue";
import MyRequests from "@/views/MyRequests.vue";
import MyOrders from "@/views/MyOrders.vue";
import Installation from "@/views/Installation.vue";
import Orders from "@/views/Orders.vue";
import Requests from "@/views/Requests.vue";
import DownloadBoard from "@/views/DownloadBoard.vue";
import Signatures from "@/views/Signatures.vue";
import CardGenerator from "@/views/CardGenerator.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/informations",
  },
  {
    path: "/informations",
    name: "Informations",
    component: Informations,
  },
  {
    path: "/cartes-de-visite",
    name: "Cartes de visites",
    component: BusinessCards,
  },
  {
    path: "/signatures-email",
    name: "Signatures e-mail",
    component: MailSignatures,
  },
  {
    path: "/pages-blanches",
    name: "Pages blanches",
    component: WhitePages,
  },
  {
    path: "/mes-demandes",
    name: "Mes demandes",
    component: MyRequests,
  },
  {
    path: "/mes-demandes/:_id",
    name: "Ma demande",
    component: MyRequests,
  },
  {
    path: "/mes-commandes",
    name: "Mes commandes",
    component: MyOrders,
  },
  {
    path: "/installation-des-signatures",
    name: "Installation",
    component: Installation,
  },
  {
    path: "/commandes",
    name: "Commandes",
    component: Orders,
  },
  {
    path: "/demandes",
    name: "Demandes",
    component: Requests,
  },
  {
    path: "/commandes/:_id",
    name: "Téléchargement",
    component: DownloadBoard,
  },
  {
    path: "/signatures",
    name: "Signatures",
    component: Signatures,
  },
  {
    path: "/generateur",
    name: "Generateur",
    component: CardGenerator,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
