var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.employee)?_c('v-card',{staticClass:"overlay ma-10",attrs:{"elevation":"10"}},[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/logo-hes-so.svg")}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"name"},[_c('div',{staticClass:"text-uppercase font-weight-bold"},[_vm._v(" "+_vm._s(_vm.employee.firstname)+" "+_vm._s(_vm.employee.lastname)+" ")])]),_c('div',{staticClass:"contact mt-2 pr-1"},[_vm._l((_vm.employee.titles),function(title,index){return _c('div',{key:("title-" + index)},[(title.config.cards)?_c('div',[_vm._v(" "+_vm._s(title.longName)+" ")]):_vm._e()])}),(_vm.employee.corporateTitle)?_c('div',[(_vm.employee.corporateTitle.config.cards)?_c('div',[_vm._v(" "+_vm._s(_vm.employee.corporateTitle.longName)+" ")]):_vm._e()]):_vm._e(),_vm._l((_vm.employee.roles),function(role,index){return _c('div',{key:("role-" + index)},[(
            role.config.cards && _vm.specialRequest.indexOf(role.longName) > -1
          )?_c('div',[_vm._v(" "+_vm._s(role[ role.longName !== "Maître d'enseignement" ? role.config.labels.cards : "longName" ])),(_vm.employee.entities.find(function (e) { return e.level === 'Département'; }))?_c('span',[_vm._v(_vm._s((" - " + (_vm.employee.entities.find(function (e) { return e.level === "Département"; }).level) + " " + (_vm.employee.entities.find(function (e) { return e.level === "Département"; }).name))))]):_vm._e()]):_vm._e(),(
            role.config.cards && _vm.specialRequest.indexOf(role.longName) === -1
          )?_c('div',[_vm._v(" "+_vm._s(_vm.shortNameDisplay.indexOf(role.longName) > -1 ? role.shortName : role[role.config.labels.cards])+" ")]):_vm._e()])})],2),_c('div',{staticClass:"bottom"},[_c('div',{staticClass:"bottom-content"},[_c('div',[_vm._v(_vm._s(_vm.employee.email))]),_c('div',[(
              _vm.employee.phones.find(function (p) { return p.type === 'landline'; }) &&
              _vm.employee.phones.find(function (p) { return p.type === 'landline'; }).config.cards
            )?_c('div',[_c('div',{staticStyle:{"width":"12px","display":"inline-block"}},[_vm._v(" "+_vm._s(_vm.employee.phones.find(function (p) { return p.type === "landline"; }).prefix ? _vm.employee.phones.find(function (p) { return p.type === "landline"; }).prefix : "T")+" ")]),_vm._v(" "+_vm._s(_vm.employee.phones.find(function (p) { return p.type === "landline"; }).number)+" ")]):_vm._e(),(
              _vm.employee.phones.find(function (p) { return p.type === 'cellphone'; }) &&
              _vm.employee.phones.find(function (p) { return p.type === 'cellphone'; }).config.cards
            )?_c('div',[_c('div',{staticStyle:{"width":"12px","display":"inline-block"}},[_vm._v(" "+_vm._s(_vm.employee.phones.find(function (p) { return p.type === "cellphone"; }).prefix ? _vm.employee.phones.find(function (p) { return p.type === "cellphone"; }).prefix : "M")+" ")]),_vm._v(" "+_vm._s(_vm.employee.phones.find(function (p) { return p.type === "cellphone"; }).number)+" ")]):_vm._e(),(
              _vm.employee.phones.find(function (p) { return p.type === 'central'; }) &&
              _vm.employee.phones.find(function (p) { return p.type === 'central'; }).config.cards
            )?_c('div',[_c('div',{staticStyle:{"width":"12px","display":"inline-block"}},[_vm._v(" "+_vm._s(_vm.employee.phones.find(function (p) { return p.type === "central"; }).prefix ? _vm.employee.phones.find(function (p) { return p.type === "central"; }).prefix : "C")+" ")]),_vm._v(" "+_vm._s(_vm.employee.phones.find(function (p) { return p.type === "central"; }).number)+" ")]):_vm._e()])])])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }