<template>
  <div class="container">
    <h2 class="mt-10">Application Mail</h2>
    <p class="alert alert-warning">
      Attention, cette manipulation peut être compliquée et nécessite quelques
      connaissances en informatique.
    </p>
    <ol>
      <li>
        Commencer par créer une signature vide dans Mail.
        <ol>
          <li>Ouvrir mail.</li>
          <li>
            Accéder aux préférences (
            <kbd>cmd + ,</kbd>).
          </li>
          <li>
            Dans l'onglet
            <code>Signatures</code>
            <ol>
              <li>
                Sélectionner le compte
                <em>heig-vd</em>.
              </li>
              <li>
                Cliquer sur
                <kbd>+</kbd> pour créer une nouvelle signature
                <em
                  >(Si vous avez plusieurs signatures, nous vous conseillons
                  d'ajouter un texte facilement reconnaissable)</em
                >.
              </li>
            </ol>
          </li>
        </ol>
      </li>
      <li>Quitter mail.</li>
      <li>Ouvrir le Finder.</li>
      <li>
        Aller dans le menu
        <code>Aller</code>.
      </li>
      <li>
        Appuyer sur la touche
        <kbd>Alt</kbd> et cliquer sur <code>Bibliothèque</code>.
      </li>
      <li>
        Depuis le dossier
        <code>Bibliothèque</code>, aller dans
        <code>Mail &gt; V8 &gt; MailData &gt; Signatures</code>.
      </li>
      <li>
        Télécharger le fichier
        <a href="#" @click.stop="downloadSignatures">zip</a> et copier le
        contenu de la signature désirée.
      </li>
      <li>
        Rechercher le fichier qui correspond à la signature précédemment créée
        <em>
          (Ce dossier peut contenir plusieurs fichiers dont l'extension est
          <code>.mailsignature</code>).
        </em>
      </li>
      <li>
        Dans ce fichier, supprimer tout le contenu depuis la ligne qui commence
        par
        <code>&lt;body style...</code> et le remplacer part le contenu copié
        précédemment.
      </li>
      <li>Sauver et fermer le fichier.</li>
      <li>
        Dans le finder, sélectionner le fichier qui vient d'être édité et
        appuyez sur
        <kbd>cmd + i</kbd>.
      </li>
      <li>
        Dans la fenêtre qui vient de s'ouvrir, vous pouvez cocher
        <code>Vérouiller</code>.
      </li>
      <li>Fermer la fenêtre.</li>
      <li>Ouvrir Mail.</li>
      <li>Vous pouvez commencer à pouvoir utiliser ca signature.</li>
    </ol>

    <h2 class="mt-10">Outlook 2011</h2>

    <ol>
      <li>Copiez le contenu de la signature désirée (<a href="/signatures?type=internal" target="_blank">interne</a> | <a href="/signatures?type=simple" target="_blank">texte</a>)</li>
      <li>Lancer Outlook 2011.</li>
      <li>
        Accéder aux préférences (
        <kbd>cmd + ,</kbd>).
      </li>
      <li>
        Cliquer sur
        <em>Signatures</em>.
      </li>
      <li>Coller contenu de la signature e-mail.</li>
      <li>
        Eventuellement, cliquer sur
        <em>Signatures par défaut...</em> pour activer la signature
        automatiquement à la création d'un nouveau mail.
      </li>
    </ol>
  </div>
</template>
<script>
export default {
  props: ["downloadSignatures"],
};
</script>

<style scoped>
code {
  padding: 2px 4px;
  font-size: 90%;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px;
}
</style>