<template>
  <v-container class="mt-10" v-if="employee">
    <div class="display-1">Signatures e-mail</div>
    <v-divider class="my-10"></v-divider>
    <v-row>
      <v-col cols="12" sm="4">
        <v-card class="pa-5" outlined>
          <v-card-text>
            <div
              v-if="
                employee.entities.find((e) => e.level === 'Institut') ||
                employee.entities.find((e) => e.level === 'Centre')
              "
            >
              <div class="mt-5 subtitle-2">LOGOS</div>
              <v-radio-group
                v-model="employee.logo.signatures"
                row
                @change="autosave"
              >
                <v-radio
                  label="École"
                  value="https://storage.googleapis.com/visual-identity/logo/2020-slim.png"
                  color="blue-grey lighten-1"
                ></v-radio>
                <v-radio
                  label="Institut"
                  :value="`https://storage.googleapis.com/visual-identity/logo/institutes/${dashify(
                    employee.entities.find((e) => e.level === 'Institut').name
                  )}.png`"
                  v-if="employee.entities.find((e) => e.level === 'Institut')"
                  color="blue-grey lighten-1"
                ></v-radio>
                <v-radio
                  label="Centre"
                  :value="`https://storage.googleapis.com/visual-identity/logo/centers/${dashify(
                    employee.entities.find((e) => e.level === 'Centre').name
                  )}.png`"
                  v-if="employee.entities.find((e) => e.level === 'Centre')"
                  color="blue-grey lighten-1"
                ></v-radio>
                <v-radio
                  label="Centre"
                  :value="`https://storage.googleapis.com/visual-identity/logo/groups/${dashify(
                    employee.entities.find((e) => e.name === 'CCDR').name
                  )}.png`"
                  v-if="employee.entities.find((e) => e.name === 'CCDR')"
                  color="blue-grey lighten-1"
                ></v-radio>
                <v-radio
                  label="GTC"
                  :value="`https://storage.googleapis.com/visual-identity/logo/groups/${employee.entities
                    .find((e) => e.key === 'VHOUJ_Custom_Info_5')
                    .name.toLowerCase()}.png`"
                  v-if="
                    employee.entities.find(
                      (e) => e.key === 'VHOUJ_Custom_Info_5'
                    )
                  "
                  color="blue-grey lighten-1"
                ></v-radio>
              </v-radio-group>
            </div>
            <div v-if="employee.titles.length">
              <div class="subtitle-2">TITRES</div>
              <v-checkbox
                class="px-3 my-0"
                v-for="(title, index) in employee.titles"
                :key="`role-${index}`"
                :label="title.longName"
                v-model="title.config.signatures"
                color="blue-grey lighten-1"
                @click="autosave"
                dense
              ></v-checkbox>
            </div>
            <div v-if="employee.corporateTitle">
              <div class="subtitle-2">TITRE INTERNE</div>
              <v-checkbox
                class="px-3 my-0"
                :label="employee.corporateTitle.longName"
                v-model="employee.corporateTitle.config.signatures"
                color="blue-grey lighten-1"
                @click="autosave"
                dense
              ></v-checkbox>
            </div>
            <div class="subtitle-2">FONCTIONS</div>

            <div v-for="(role, index) in employee.roles" :key="`role-${index}`">
              <v-checkbox
                class="px-3 my-0"
                :label="
                  shortNameDisplay.indexOf(role.longName) > -1
                    ? role.shortName
                    : role.longName
                "
                v-model="role.config.signatures"
                color="blue-grey lighten-1"
                @click="autosave"
                :disabled="
                  role.config.signatures &&
                  employee.roles.filter((r) => r.config.signatures).length === 1
                "
                dense
              ></v-checkbox>
              <v-radio-group
                class="px-10 my-0"
                row
                v-if="
                  role.longName &&
                  role.shortName &&
                  role.longName !== role.shortName &&
                  role.shortName !== '-' &&
                  shortNameDisplay.indexOf(role.longName) === -1
                "
                v-model="role.config.labels.signatures"
                @change="autosave"
                :disabled="!role.config.signatures"
              >
                <v-radio
                  label="Libéllé court"
                  color="blue-grey lighten-1"
                  value="shortName"
                ></v-radio>
                <v-radio
                  label="Libéllé long"
                  color="blue-grey lighten-1"
                  value="longName"
                ></v-radio>
              </v-radio-group>
            </div>
            <div class="mt-5 subtitle-2">TÉLÉPHONES</div>
            <v-checkbox
              class="px-3 my-0"
              v-for="(phone, index) in employee.phones"
              :key="`phone-${index}`"
              :label="phone.number"
              v-model="phone.config.signatures"
              color="blue-grey lighten-1"
              @click="autosave"
              dense
            ></v-checkbox>
          </v-card-text>
        </v-card>

        <v-card outlined class="mt-3">
          <v-card-text class="pa-10">
            <div class="my-2">
              La signature e-mail générée par l’outil correspondance est la
              seule signature autorisée, conforme à la nouvelle charte
              graphique.
            </div>
            <div class="my-2">
              Les demandes de modification des données personnelles (titres,
              fonctions, numéros de téléphone…) peuvent être adressées aux
              service Ressources humaines à l’aide du formulaire prévu à cet
              effet sous la rubrique
              <a href="/informations">« informations »</a>. Vous y trouvez
              également la note explicative sur l’utilisation des cartes de
              visite et des signatures e-mail.
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="8">
        <new-signature
          :employee="employee"
          :specialRequest="specialRequest"
          :shortNameDisplay="shortNameDisplay"
        />
        <text-signature
          :employee="employee"
          :specialRequest="specialRequest"
          :shortNameDisplay="shortNameDisplay"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import NewSignature from "@/components/NewSignature";
import TextSignature from "@/components/TextSignature";
import dashify from "dashify";

export default {
  props: [
    "employee",
    "autosave",
    "switchMode",
    "specialRequest",
    "shortNameDisplay",
  ],
  components: {
    NewSignature,
    TextSignature,
  },
  methods: {
    dashify,
  },
};
</script>
