<template>
  <v-container class="mt-10" v-if="employee">
    <div class="display-1">Générateur de cartes de visite + signatures</div>
    <v-divider class="my-10"></v-divider>
    <v-row>
      <v-col cols="12" sm="4">
        <v-card class="pa-5" outlined>
          <v-card-text>
            <v-text-field
              outlined
              dense
              label="Prénom"
              v-model="employee.firstname"
            ></v-text-field>
            <v-text-field
              outlined
              dense
              label="Nom"
              v-model="employee.lastname"
            ></v-text-field>
            <v-text-field
              outlined
              dense
              label="E-mail"
              v-model="employee.email"
            ></v-text-field>
            <div>
              <div class="subtitle-2">LOGO</div>
              <v-autocomplete
                outlined
                dense
                :items="logos"
                v-model="employee.logo.cards"
              ></v-autocomplete>
            </div>
            <div>
              <div class="subtitle-2">TITRES</div>
              <v-text-field
                outlined
                dense
                placeholder="Titres"
                v-model="employee.titles[0].longName"
              ></v-text-field>
            </div>
            <div>
              <div class="subtitle-2">TITRE INTERNE</div>
              <v-text-field
                outlined
                dense
                placeholder="Titres"
                v-model="employee.corporateTitle.longName"
              ></v-text-field>
            </div>
            <div class="subtitle-2">FONCTIONS</div>
            <v-text-field
              outlined
              dense
              placeholder="Fonction 1"
              v-model="employee.roles[0].longName"
            ></v-text-field>
            <v-text-field
              outlined
              dense
              placeholder="Fonction 2"
              v-model="employee.roles[1].longName"
            ></v-text-field>
            <v-text-field
              outlined
              dense
              placeholder="Fonction 3"
              v-model="employee.roles[2].longName"
            ></v-text-field>
            <div class="subtitle-2">TÉLÉPHONES</div>
            <v-container class="pa-0">
              <v-row>
                <v-col sm="4">
                  <v-text-field
                    outlined
                    dense
                    placeholder="Préfixe 1"
                    v-model="employee.phones[0].prefix"
                    :hide-details="true"
                  ></v-text-field
                ></v-col>
                <v-col sm="8">
                  <v-text-field
                    outlined
                    dense
                    placeholder="Téléphone 1"
                    v-model="employee.phones[0].number"
                    :hide-details="true"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col sm="4">
                  <v-text-field
                    outlined
                    dense
                    placeholder="Préfixe 2"
                    v-model="employee.phones[1].prefix"
                    :hide-details="true"
                  ></v-text-field
                ></v-col>
                <v-col sm="8">
                  <v-text-field
                    outlined
                    dense
                    placeholder="Téléphone 2"
                    v-model="employee.phones[1].number"
                    :hide-details="true"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col sm="4">
                  <v-text-field
                    outlined
                    dense
                    placeholder="Préfixe 3"
                    v-model="employee.phones[2].prefix"
                    :hide-details="true"
                  ></v-text-field
                ></v-col>
                <v-col sm="8">
                  <v-text-field
                    outlined
                    dense
                    placeholder="Téléphone 3"
                    v-model="employee.phones[2].number"
                    :hide-details="true"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <div class="subtitle-2">ADRESSE</div>
            <v-text-field
              outlined
              dense
              placeholder="Rue"
              v-model="employee.address.street"
            ></v-text-field>
            <v-text-field
              outlined
              dense
              placeholder="Case postale"
              v-model="employee.address.po"
            ></v-text-field>
            <v-text-field
              outlined
              dense
              placeholder="NPA"
              v-model="employee.address.zip"
            ></v-text-field>
            <v-text-field
              outlined
              dense
              placeholder="Ville"
              v-model="employee.address.cityName"
            ></v-text-field>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="8">
        <v-card outlined>
          <v-card-title class="justify-center"
            ><card-front
              :employee="computedEmployee"
              :specialRequest="[]"
              :shortNameDisplay="[]" /><card-back :employee="computedEmployee"
          /></v-card-title>
        </v-card>
        <v-btn block outlined class="mt-5" @click="downloadFile"
          >Télécharger la planche</v-btn
        >
        <new-signature
          class="mt-10"
          :employee="computedEmployee"
          :specialRequest="[]"
          :shortNameDisplay="[]"
        />
        <text-signature
          :employee="computedEmployee"
          :specialRequest="[]"
          :shortNameDisplay="[]"
        />
        <v-btn block outlined class="mt-5" @click="downloadSignatures"
          >Télécharger les signatures</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CardFront from "@/components/CardFront";
import CardBack from "@/components/CardBack";
import NewSignature from "@/components/NewSignature";
import TextSignature from "@/components/TextSignature";

import axios from "axios";
import fileDownload from "js-file-download";

export default {
  props: ["openSnackbar"],
  data: () => ({
    logos: [
      {
        text: "HEIG-VD (slim)",
        value:
          "https://storage.googleapis.com/visual-identity/logo/2020-slim.svg",
      },
      {
        text: "HEIG-VD (FR)",
        value:
          "https://storage.googleapis.com/visual-identity/logo/2020-full.svg",
      },
      {
        text: "HEIG-VD (EN)",
        value:
          "https://storage.googleapis.com/visual-identity/logo/2020-en.svg",
      },
      {
        text: "COMATEC",
        value:
          "https://storage.googleapis.com/visual-identity/logo/institutes/comatec.svg",
      },
      {
        text: "IAI",
        value:
          "https://storage.googleapis.com/visual-identity/logo/institutes/iai.svg",
      },
      {
        text: "IESE",
        value:
          "https://storage.googleapis.com/visual-identity/logo/institutes/iese.svg",
      },
      {
        text: "IGT",
        value:
          "https://storage.googleapis.com/visual-identity/logo/institutes/igt.svg",
      },
      {
        text: "IICT",
        value:
          "https://storage.googleapis.com/visual-identity/logo/institutes/iict.svg",
      },
      {
        text: "IIDE",
        value:
          "https://storage.googleapis.com/visual-identity/logo/institutes/iide.svg",
      },
      {
        text: "INSIT",
        value:
          "https://storage.googleapis.com/visual-identity/logo/institutes/insit.svg",
      },
      {
        text: "MEI",
        value:
          "https://storage.googleapis.com/visual-identity/logo/institutes/mei.svg",
      },
      {
        text: "REDS",
        value:
          "https://storage.googleapis.com/visual-identity/logo/institutes/reds.svg",
      },
      {
        text: "AddiPole",
        value:
          "https://storage.googleapis.com/visual-identity/logo/groups/addipole.svg",
      },
      {
        text: "CCDR",
        value:
          "https://storage.googleapis.com/visual-identity/logo/groups/ccdr.svg",
      },
      {
        text: "Énergies",
        value:
          "https://storage.googleapis.com/visual-identity/logo/groups/energies.svg",
      },
      {
        text: "HEE",
        value:
          "https://storage.googleapis.com/visual-identity/logo/groups/hee.svg",
      },
      {
        text: "mecatronYx",
        value:
          "https://storage.googleapis.com/visual-identity/logo/groups/mecatronyx.svg",
      },
      {
        text: "Y-Security",
        value:
          "https://storage.googleapis.com/visual-identity/logo/groups/y-security.svg",
      },
      {
        text: "Centre Formation continue",
        value:
          "https://storage.googleapis.com/visual-identity/logo/centers/formation-continue.svg",
      },
      {
        text: "Centre Formation de base",
        value:
          "https://storage.googleapis.com/visual-identity/logo/centers/formation-de-base.svg",
      },
      {
        text: "Centre Ra&D",
        value:
          "https://storage.googleapis.com/visual-identity/logo/centers/ra-d.svg",
      },
      {
        text: "Centre Relations internationales",
        value:
          "https://storage.googleapis.com/visual-identity/logo/centers/relations-internationales.svg",
      },
      {
        text: "Bibliothèque",
        value:
          "https://storage.googleapis.com/visual-identity/logo/autre/biblio.svg",
      },
      {
        text: "LESBAT",
        value:
          "https://storage.googleapis.com/visual-identity/logo/labos/lesbat.svg",
      },
      {
        text: "LEC (FR)",
        value:
          "https://storage.googleapis.com/visual-identity/logo/labos/lec-fr.svg",
      },
      {
        text: "LEC (EN)",
        value:
          "https://storage.googleapis.com/visual-identity/logo/labos/lec-en.svg",
      },
      {
        text: "FABRIK",
        value:
          "https://storage.googleapis.com/visual-identity/logo/labos/fabrik.svg",
      },
      {
        text: "Institut des énergies",
        value:
          "https://storage.googleapis.com/visual-identity/logo/institutes/institut-des-energies.svg",
      },
      {
        text: "Service Informatique & Systèmes d'information",
        value:
          "https://storage.googleapis.com/visual-identity/logo/autre/si.svg",
      },
    ],
    employee: {
      phones: [
        {
          number: "",
          type: "landline",
          prefix: "T",
          config: { cards: false, signatures: false },
        },
        {
          number: "",
          type: "cellphone",
          prefix: "M",
          config: { cards: false, signatures: false },
        },
        {
          number: "",
          type: "central",
          prefix: "C",
          config: { cards: false, signatures: false },
        },
      ],
      roles: [
        {
          longName: "",
          config: {
            cards: false,
            signatures: false,
          },
        },
        {
          longName: "",
          config: {
            cards: false,
            signatures: false,
          },
        },
        {
          longName: "",
          config: {
            cards: false,
            signatures: false,
          },
        },
      ],
      titles: [
        {
          longName: "",
          config: {
            cards: false,
            signatures: false,
          },
        },
      ],
      corporateTitle: {
        longName: "",
        config: { cards: false, signatures: false },
      },
      lastname: "",
      firstname: "",
      email: "",
      address: {
        street: "",
        po: "",
        city: "",
        cityName: "",
      },
      logo: {
        cards:
          "https://storage.googleapis.com/visual-identity/logo/2020-slim.svg",
      },
    },
  }),
  components: { CardFront, CardBack, NewSignature, TextSignature },
  methods: {
    async downloadFile() {
      try {
        const { data } = await axios({
          method: "post",
          url: `${process.env.VUE_APP_API_URI}/admin/generator?type=cards`,
          responseType: "arraybuffer",
          data: this.computedEmployee,
        });
        fileDownload(
          data,
          `${this.employee.firstname}-${this.employee.lastname}-planche.pdf`
        );
      } catch (error) {
        this.openSnackbar({});
      }
    },
    async downloadSignatures() {
      try {
        const { data } = await axios({
          method: "post",
          url: `${process.env.VUE_APP_API_URI}/admin/generator?type=signatures`,
          responseType: "arraybuffer",
          data: this.computedEmployee,
        });
        fileDownload(data, "signatures.zip");
      } catch (error) {
        console.log(error);
      }
    },
  },
  watch: {
    "employee.address.zip"() {
      this.employee.address.city = `${this.employee.address.zip}, ${this.employee.address.cityName}`;
    },
    "employee.address.cityName"() {
      this.employee.address.city = `${this.employee.address.zip}, ${this.employee.address.cityName}`;
    },
  },
  computed: {
    computedEmployee() {
      let computed = { ...this.employee };
      computed.phones = computed.phones.filter((p) => p.number);
      computed.logo.signatures = computed.logo.cards.replace(".svg", ".png");
      if (computed.corporateTitle.longName) {
        computed.corporateTitle.config = { cards: true, signatures: true };
      }
      computed.titles = computed.titles.map((t) => {
        if (t.longName) {
          t.config = { cards: true, signatures: true };
        }
        return t;
      });
      computed.roles = computed.roles.map((r) => {
        if (r.longName) {
          r.config = {
            cards: true,
            signatures: true,
            labels: { cards: "longName", signatures: "longName" },
          };
        }
        return r;
      });
      computed.phones = computed.phones.map((p) => {
        p.config = { cards: true, signatures: true };
        return p;
      });
      return computed;
    },
  },
};
</script>
