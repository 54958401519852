<template>
  <v-app v-if="employee">
    <v-app-bar app flat v-if="this.$route.name !== 'Signatures'">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <user-profile
        :setEmployee="setEmployee"
        :setLocalEmployee="setLocalEmployee"
        :resetEmployee="resetEmployee"
      ></user-profile>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      app
      v-if="this.$route.name !== 'Signatures'"
    >
      <v-list-item>
        <v-list-item-content>
          <v-img
            src="https://storage.googleapis.com/visual-identity/logo/2020-slim.svg"
            height="80"
            :contain="true"
          ></v-img>
          <v-list-item-title class="title mt-5"
            >CORRESPONDANCE</v-list-item-title
          >
          <v-list-item-subtitle>Vos données personnelles</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list dense nav>
        <v-subheader>APERÇU</v-subheader>
        <v-list-item link to="/informations">
          <v-list-item-icon>
            <v-icon>mdi-information-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Informations</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-subheader>CONFIGURATION</v-subheader>
        <v-list-item link to="/cartes-de-visite">
          <v-list-item-icon>
            <v-icon>mdi-card-account-details-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Cartes de visite</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link to="/signatures-email">
          <v-list-item-icon>
            <v-icon>mdi-card-account-mail-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Signatures e-mail</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- <v-list-item link to="/pages-blanches">
          <v-list-item-icon>
            <v-icon>mdi-card-account-phone-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Pages blanches</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
        <v-subheader>SUIVI</v-subheader>
        <v-list-item link to="/mes-commandes" :disabled="switchMode || !employee.email || !employee.address.street">
          <v-list-item-icon>
            <v-icon>mdi-basket-fill</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Mes commandes</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link to="/mes-demandes" :disabled="switchMode">
          <v-list-item-icon>
            <v-icon>mdi-help-circle-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Mes demandes</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-subheader>INSTALLATION</v-subheader>
        <v-list-item
          link
          to="/installation-des-signatures"
          :disabled="switchMode || !employee.email || !employee.address.street"
        >
          <v-list-item-icon>
            <v-icon>mdi-cog-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Installation des signatures</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list
        dense
        nav
        v-if="this.$keycloak.hasResourceRole('admin', 'correspondance')"
      >
        <v-subheader>GESTION</v-subheader>
        <v-list-item
          link
          to="/commandes?status=pending"
          :disabled="switchMode || !employee.email || !employee.address.street"
          v-if="
            this.$keycloak.hasResourceRole('admin-orders', 'correspondance')
          "
        >
          <v-list-item-icon>
            <v-icon>mdi-clipboard-list-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Commandes</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-badge
              color="red darken-1"
              inline
              :content="statuses.orders"
              v-if="statuses.orders"
            ></v-badge>
          </v-list-item-action>
        </v-list-item>
        <v-list-item
          link
          to="/demandes?status=pending"
          :disabled="switchMode || !employee.email || !employee.address.street"
          v-if="
            this.$keycloak.hasResourceRole('admin-requests', 'correspondance')
          "
        >
          <v-list-item-icon>
            <v-icon>mdi-comment-question-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Demandes</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-badge
              color="red darken-1"
              inline
              :content="statuses.requests"
              v-if="statuses.requests"
            ></v-badge>
          </v-list-item-action>
        </v-list-item>
        <v-list-item
          link
          to="/generateur"
          :disabled="switchMode || !employee.email || !employee.address.street"
          v-if="
            this.$keycloak.hasResourceRole('admin-generator', 'correspondance')
          "
        >
          <v-list-item-icon>
            <v-icon>mdi-auto-fix</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Générateur</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template
        v-slot:append
        v-if="autosaveRoutes.indexOf($route.path) > -1 && !switchMode"
      >
        <div class="pa-2 text-center grey lighten-2" v-if="employee">
          <div class="caption">
            Dernière sauvegarde
            <div>
              {{ format(parseISO(employee.updatedAt), "dd.MM.yyyy HH:mm:ss") }}
            </div>
          </div>
        </div>
      </template>
    </v-navigation-drawer>
    <v-main>
      <router-view
        :employee="switchMode ? admin.employee : employee"
        :photo="switchMode ? admin.photo : photo"
        :autosave="autosave"
        :openSnackbar="openSnackbar"
        :orderStatusLabels="orderStatusLabels"
        :requestStatusLabels="requestStatusLabels"
        :switchMode="switchMode"
        :updateNotifications="updateNotifications"
        :specialRequest="specialRequest"
        :shortNameDisplay="shortNameDisplay"
      />
      <v-snackbar v-model="snackbar.visible" :color="snackbar.color" right>
        {{ snackbar.message }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.visible = false"
            >Fermer</v-btn
          >
        </template>
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
import UserProfile from "@/components/UserProfile";
import axios from "axios";
import { format, parseISO } from "date-fns";
import { debounce } from "lodash";

export default {
  async mounted() {
    try {
      const { data: employee } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/employees`,
      });
      this.employee = employee;
    } catch (error) {
      if (error.response.status === 401) {
        this.$keycloak.logoutFn();
      }
    }

    try {
      const { data: photo } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/photos`,
        responseType: "blob",
      });
      this.photo = URL.createObjectURL(photo);
    } catch (error) {
      this.photo = undefined;
    }
    if (this.$keycloak.hasResourceRole("admin-orders", "correspondance")) {
      try {
        const { data: statuses } = await axios({
          method: "get",
          url: `${process.env.VUE_APP_API_URI}/admin/statuses`,
        });
        this.statuses = statuses;
      } catch (error) {
        this.openSnackbar({ color: "error", message: error });
      }
    }
  },
  components: { UserProfile },
  data: () => ({
    drawer: null,
    employee: undefined,
    photo: undefined,
    admin: {
      employee: undefined,
      photo: undefined,
    },
    statuses: { orders: undefined, requests: undefined },
    switchMode: false,
    format,
    parseISO,
    snackbar: {
      visible: false,
      color: "",
      message: "",
    },
    autosaveRoutes: [
      "/cartes-de-visite",
      "/signatures-email",
      "/pages-blanches",
    ],
    orderStatusLabels: {
      pending: "Transmise à l'impression",
      delivered: "Livrée",
      canceled: "Annulée",
    },
    requestStatusLabels: {
      pending: "À traiter",
      processing: "En cours",
      rejected: "Rejetée",
      complete: "Terminée",
    },
    specialRequest: [
      "Professeure HES ordinaire",
      "Professeur HES ordinaire",
      "Professeure HES associée",
      "Professeur HES associé",
      "Maître d'enseignement",
    ],
    shortNameDisplay: [
      "Directrice de la Haute Ecole d'Ingénierie et de Gestion du Canton de Vaud (HEIG-VD)",
      "Directrice opérationnelle de la Haute Ecole d'Ingénierie et de Gestion du Canton de Vaud (HEIG-VD)",
      "Directeur adjoint de la Haute Ecole d'Ingénierie et de Gestion du Canton de Vaud (HEIG-VD)",
    ],
  }),
  methods: {
    save: async function () {
      try {
        const { data: employee } = await axios({
          method: "patch",
          url: `${process.env.VUE_APP_API_URI}/employees/${this.employee._id}`,
          data: this.employee,
        });
        this.employee = employee;
      } catch (error) {
        this.openSnackbar({ color: "error", message: error });
      }
    },
    autosave: debounce(function () {
      if (!this.switchMode) {
        this.save();
      }
    }, 500),
    openSnackbar({ color, message }) {
      this.snackbar = {
        visible: true,
        color,
        message,
      };
    },
    updateNotifications(type, operation) {
      this.statuses[type] += operation;
    },
    async setEmployee(_id) {
      try {
        const { data: employee } = await axios({
          method: "get",
          url: `${process.env.VUE_APP_API_URI}/admin/employees/${_id}`,
        });
        this.admin.employee = employee;
      } catch (error) {
        this.openSnackbar({ color: "error", message: error });
      }

      try {
        const { data: photo } = await axios({
          method: "get",
          url: `${process.env.VUE_APP_API_URI}/admin/employees/${_id}/photos`,
          responseType: "blob",
        });
        this.admin.photo = URL.createObjectURL(photo);
      } catch (error) {
        this.admin.photo = undefined;
      }
      this.switchMode = true;
    },
    async setLocalEmployee(selected) {
      try {
        const { data: photo } = await axios({
          method: "get",
          url: `${process.env.VUE_APP_API_URI}/admin/photos?idisa=${selected.idisa}`,
          responseType: "blob",
        });
        this.admin.photo = URL.createObjectURL(photo);
      } catch (error) {
        this.openSnackbar({ color: "error", message: error });
      }

      this.admin.employee = selected;
      this.switchMode = true;
    },
    resetEmployee() {
      this.switchMode = false;
    },
  },
};
</script>

<style>
@font-face {
  font-family: "DINPro Light";
  font-style: normal;
  font-weight: normal;
  src: local("DINPro Light"),
    url("./assets/fonts/DINPro-Light.woff") format("woff");
}

@font-face {
  font-family: "DINPro Medium";
  font-style: normal;
  font-weight: normal;
  src: local("DINPro Medium"),
    url("./assets/fonts/DINPro-Medium.woff") format("woff");
}

@font-face {
  font-family: "DINPro Bold";
  font-style: normal;
  font-weight: normal;
  src: local("DINPro Bold"),
    url("./assets/fonts/DINPro-Bold.woff") format("woff");
}

@font-face {
  font-family: "DINPro Black";
  font-style: normal;
  font-weight: normal;
  src: local("DINPro Black"),
    url("./assets/fonts/DINPro-Black.woff") format("woff");
}

@font-face {
  font-family: "DINPro Regular";
  font-style: normal;
  font-weight: normal;
  src: local("DINPro-Regular"),
    url("./assets/fonts/DINPro-Regular.woff") format("woff");
}

@font-face {
  font-family: "CircularXXTT Regular";
  font-style: normal;
  font-weight: normal;
  src: local("CircularXXTT-Regular"),
    url("./assets/fonts/CircularXXTT-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "CircularXXTT Bold";
  font-style: normal;
  font-weight: normal;
  src: local("CircularXXTT-Bold"),
    url("./assets/fonts/CircularXXTT-Bold.ttf") format("truetype");
}
</style>
