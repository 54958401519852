<template>
  <v-container class="mt-10">
    <div class="display-1">Mes demandes</div>
    <v-divider class="mt-10 mb-3"></v-divider>
    <v-row>
      <v-col cols="12" sm="12">
        <div class="text-right">
          <v-btn outlined text class="mb-5" to="/informations"
            >Nouvelle demande</v-btn
          >
        </div>
        <v-card outlined>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="requests"
              :items-per-page="5"
              class="elevation-0"
              :footer-props="{
                itemsPerPageText: 'Demandes par page',
              }"
            >
              <template v-slot:body="{ items }">
                <tbody class="text-left">
                  <tr
                    v-for="item in items"
                    :key="item._id"
                    @click="openDialog(item)"
                  >
                    <td>
                      {{ format(parseISO(item.createdAt), "dd.MM.yyyy") }}
                    </td>
                    <td>
                      {{
                        item.message.length > 20
                          ? item.message.substring(0, 20 - 3) + "..."
                          : item.message
                      }}
                    </td>
                    <td>{{ requestStatusLabels[item.status] }}</td>
                  </tr>
                </tbody>
              </template>
              <!-- eslint-disable-next-line vue/valid-v-slot justification: possible according to vuetify documentation -->
              <template
                v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }"
                >{{ pageStart }}-{{ pageStop }} de {{ itemsLength }}</template
              >
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" width="500" @click:outside="closeDialog">
      <v-card>
        <v-card-title class="headline grey lighten-3">
          Détails de la demande
        </v-card-title>

        <v-card-text class="mt-5" v-if="editedItem">
          <v-container>
            <v-row>
              <v-col cols="12" sm="12">
                <v-textarea
                  outlined
                  dense
                  v-model="editedItem.message"
                  disabled
                  auto-grow
                  label="Message"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col cols="12" sm="12">
                <v-select
                  label="Statut"
                  outlined
                  dense
                  class="mt-5"
                  :items="statuses"
                  v-model="editedItem.status"
                  color="blue-grey lighten-1"
                  disabled
                ></v-select>
                <v-textarea
                  outlined
                  dense
                  v-if="
                    ['complete', 'rejected'].indexOf(editedItem.status) > -1
                  "
                  label="Commentaires"
                  v-model="editedItem.comments"
                  disabled
                  auto-grow
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import { format, parseISO } from "date-fns";

export default {
  async mounted() {
    const { data: requests } = await axios({
      method: "get",
      url: `${process.env.VUE_APP_API_URI}/requests`,
    });
    this.requests = requests;

    if (this.$route.params._id) {
      this.editedItem = this.requests.find(
        (r) => r._id === this.$route.params._id
      );
      this.dialog = true;
    }
  },
  props: ["requestStatusLabels"],
  data: () => ({
    requests: [],
    editedItem: undefined,
    dialog: false,
    headers: [
      {
        text: "Date",
        align: "start",
        sortable: false,
        value: "createdAt",
      },
      {
        text: "Message",
        align: "start",
        sortable: false,
        value: "message",
      },
      {
        text: "Statut",
        align: "start",
        sortable: false,
        value: "status",
      },
    ],
    format,
    parseISO,
  }),
  methods: {
    openDialog(item) {
      this.editedItem = {
        ...item,
        comments: item.comments ? item.comments : "",
      };
      this.dialog = true;
    },
    closeDialog() {
      this.editedItem = undefined;
      this.dialog = false;
    },
  },
  computed: {
    statuses() {
      return Object.keys(this.requestStatusLabels).map((key) => ({
        text: this.requestStatusLabels[key],
        value: key,
      }));
    },
  },
};
</script>
