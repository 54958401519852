<template>
  <div>Téléchargement de la planche...</div>
</template>

<script>
import axios from "axios";
import fileDownload from "js-file-download";

export default {
  mounted() {
    this.downloadFile(this.$route.params._id);
    this.$router.push("/commandes?status=pending");
  },
  methods: {
    async downloadFile(_id) {
      try {
        const { data } = await axios({
          method: "get",
          url: `${process.env.VUE_APP_API_URI}/admin/orders/${_id}`,
          responseType: "arraybuffer",
        });
        fileDownload(data, `planche.pdf`);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>