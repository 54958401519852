var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mt-10"},[_c('div',{staticClass:"display-1"},[_vm._v("Mes commandes")]),_c('v-divider',{staticClass:"mt-10 mb-3"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('div',{staticClass:"text-right"},[_c('v-btn',{staticClass:"mb-5",attrs:{"outlined":"","text":"","to":"/cartes-de-visite"}},[_vm._v("Nouvelle commande")])],1),_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.orders,"items-per-page":5,"footer-props":{
              itemsPerPageText: 'Commandes par page',
            }},scopedSlots:_vm._u([{key:"body",fn:function(ref){
            var items = ref.items;
return [_c('tbody',{staticClass:"text-left"},_vm._l((items),function(item){return _c('tr',{key:item._id},[_c('td',[_vm._v(" "+_vm._s(_vm.format(_vm.parseISO(item.createdAt), "dd.MM.yyyy"))+" ")]),_c('td',[_vm._v(_vm._s(item.quantity))]),_c('td',[_vm._v(_vm._s(_vm.orderStatusLabels[item.status]))])])}),0)]}},{key:"footer.page-text",fn:function(ref){
            var pageStart = ref.pageStart;
            var pageStop = ref.pageStop;
            var itemsLength = ref.itemsLength;
return [_vm._v(_vm._s(pageStart)+"-"+_vm._s(pageStop)+" de "+_vm._s(itemsLength))]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }