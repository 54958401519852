<template>
  <v-container class="mt-10">
    <div class="display-1">Demandes</div>
    <v-divider class="my-10"></v-divider>
    <v-row>
      <v-col cols="12" sm="12">
        <router-link to="/demandes?status=pending">À traiter</router-link>
        <router-link
          class="ml-2"
          to="/demandes?status=processing,rejected,complete"
          >Historique</router-link
        >
        <v-card outlined class="mt-2">
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="requests"
              :items-per-page="5"
              :server-items-length="total"
              class="elevation-0"
              :options.sync="options"
              :footer-props="{
                itemsPerPageText: 'Demandes par page',
              }"
            >
              <template v-slot:body="{ items }">
                <tbody class="text-left">
                  <tr
                    v-for="item in items.filter(
                      (i) =>
                        $route.query.status.split(',').indexOf(i.status) > -1
                    )"
                    :key="item._id"
                    @click="openDialog(item)"
                  >
                    <td>
                      {{ format(parseISO(item.createdAt), "dd.MM.yyyy") }}
                    </td>
                    <td>
                      {{ item.createdBy.firstname }}
                      {{ item.createdBy.lastname }}
                    </td>
                    <td>{{ requestStatusLabels[item.status] }}</td>
                  </tr>
                </tbody>
              </template>
              <!-- eslint-disable-next-line vue/valid-v-slot justification: possible according to vuetify documentation -->
              <template
                v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }"
                >{{ pageStart }}-{{ pageStop }} de {{ itemsLength }}</template
              >
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" width="600" @click:outside="closeDialog">
      <v-card>
        <v-card-title class="headline grey lighten-3">
          Mise à jour du statut de la demande
        </v-card-title>

        <v-card-text class="mt-5" v-if="editedItem">
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <div class="subtitle-2">DEMANDEUR</div>
                <div>
                  {{ editedItem.createdBy.firstname }}
                  {{ editedItem.createdBy.lastname }}
                </div>
                <div class="subtitle-2 mt-2">EMAIL</div>
                <div>
                  <a :href="`mailto:${editedItem.createdBy.email}`">{{
                    editedItem.createdBy.email
                  }}</a>
                </div>
              </v-col>
              <v-col cols="12" sm="6">
                <div class="subtitle-2">SITE</div>
                <div>
                  {{ editedItem.createdBy.address.location }}
                </div>
                <div class="subtitle-2 mt-2">ADRESSE</div>
                <div>
                  {{ editedItem.createdBy.address.street
                  }}<span v-if="editedItem.createdBy.address.po">, Case postale</span>
                </div>
                <div>
                  CH-{{ editedItem.createdBy.address.city.split(",")[0]
                  }}{{ editedItem.createdBy.address.city.split(",")[1] }}
                </div>
              </v-col>
            </v-row>
            <v-divider class="mt-5"></v-divider>
            <v-row>
              <v-col cols="12" sm="12">
                <v-textarea
                  outlined
                  dense
                  v-model="editedItem.message"
                  disabled
                  auto-grow
                  label="Message"
                  class="mt-5"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col cols="12" sm="12">
                <v-select
                  label="Statut"
                  outlined
                  dense
                  class="mt-5"
                  :items="statuses"
                  v-model="editedItem.status"
                  color="blue-grey lighten-1"
                ></v-select>
                <v-textarea
                  outlined
                  dense
                  label="Commentaires"
                  v-model="editedItem.comments"
                  auto-grow
                ></v-textarea>
                <div>NB : un e-mail de notification est envoyé au demandeur une fois que le statut de sa demande passe de <b>À traiter/En cours</b> à <b>Rejetée/Terminée</b>. Pour ces 2 derniers statuts, le commentaire est alors visible pour le demandeur.</div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="updateStatus">Mettre à jour</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import { format, parseISO } from "date-fns";

export default {
  props: ["requestStatusLabels", "openSnackbar", "updateNotifications"],
  data: () => ({
    options: {},
    requests: [],
    total: 0,
    headers: [
      {
        text: "Date",
        align: "start",
        sortable: false,
        value: "createdAt",
      },
      {
        text: "Demandeur",
        align: "start",
        sortable: false,
        value: "createdAt",
      },
      {
        text: "Statut",
        align: "start",
        sortable: false,
        value: "status",
      },
    ],
    editedItem: undefined,
    dialog: false,
    format,
    parseISO,
  }),
  computed: {
    statuses() {
      return Object.keys(this.requestStatusLabels).map((key) => ({
        text: this.requestStatusLabels[key],
        value: key,
      }));
    },
  },
  methods: {
    async getDataFromApi() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const { data } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/admin/requests?page=${page}&sortBy=${sortBy}&sortDesc=${sortDesc}&itemsPerPage=${itemsPerPage}&status=${this.$route.query.status}`,
      });
      this.loading = false;
      let { docs: requests, totalDocs } = data;
      const total = totalDocs;
      return {
        requests,
        total,
      };
    },
    async updateStatus() {
      try {
        const oldStatus = this.requests.find(
          (i) => i._id === this.editedItem._id
        ).status;
        const { data: updated } = await axios({
          method: "patch",
          url: `${process.env.VUE_APP_API_URI}/admin/requests/${this.editedItem._id}`,
          data: {
            status: this.editedItem.status,
            comments: this.editedItem.comments,
          },
        });
        this.requests.find((i) => i._id === updated._id).status =
          updated.status;
        this.requests.find((i) => i._id === updated._id).comments =
          updated.comments;
        this.closeDialog();
        this.openSnackbar({ color: "success", message: "Statut mis à jour" });
        if (oldStatus === "pending") {
          this.updateNotifications("requests", -1);
        }
        if (updated.status === "pending") {
          this.updateNotifications("requests", 1);
        }
      } catch (error) {
        this.openSnackbar({});
      }
    },
    openDialog(item) {
      this.editedItem = {
        ...item,
        comments: item.comments ? item.comments : "",
      };
      this.dialog = true;
    },
    closeDialog() {
      this.editedItem = undefined;
      this.dialog = false;
    },
  },
  watch: {
    async $route() {
      this.options.page = null;
      this.options.page = 1;
    },
    options: {
      async handler() {
        const { requests, total } = await this.getDataFromApi();
        this.requests = requests;
        this.total = total;
      },
      deep: true,
    },
  },
};
</script>
