<template>
  <v-card class="overlay ma-10" v-if="employee" elevation="10">
    <img
      :src="employee.logo.cards"
      class="logo"
    />
    <div class="content">
      <div>Haute École d'Ingénierie</div>
      <div>et de Gestion du Canton de Vaud</div>
      <div>{{employee.address.street}}<span v-if="employee.address.po">, Case postale</span></div>
      <div>CH-{{employee.address.city.split(',')[0]}} {{employee.address.city.split(',')[1]}}<span class="ml-8 text-decoration-underline">heig-vd.ch</span></div>
    </div>
  </v-card>
</template>

<script>
export default {
  props: ["employee"],
};
</script>

<style scoped>
div.business-card {
  width: 95mm;
  height: 55mm;
  border-top: 1px solid;
  border-bottom: 1px solid;
  margin-bottom: 40px;
  position: relative;
  padding: 0mm 5mm;
}
.business-card {
  display: inline-block;
}
.logo {
  height: 40px;
  position: absolute;
  top: 20px;
  left: 20px;
  border-radius: unset !important;
}
.logo-hes {
  position: fixed;
  top: 138px;
  left: 200px;
  height: 20px;
}
.content {
  display: inline-block;
  position: absolute;
  top: 120px;
  left: 85px;
  font-size: 8pt;
  height: 100%;
  font-family: "CircularXXTT Regular";
  color: black;  line-height: initial;
}
.overlay {
  line-height: 1;
  position: relative;
  height: 55mm;
  width: 85mm;
  transform: translate(-1mm, -0.75mm);
  background: white;
}
.roles {
  transform: translate(0, 3px);
}
.entities {
  transform: translate(0, 25px);
  font-family: "DINPro Medium";
}
.contact {
  transform: translate(0, 65px);
}
</style>