<template>
  <v-container class="mt-10">
    <div class="display-1">Mes commandes</div>
    <v-divider class="mt-10 mb-3"></v-divider>
    <v-row>
      <v-col cols="12" sm="12">
        <div class="text-right">
          <v-btn outlined text class="mb-5" to="/cartes-de-visite"
            >Nouvelle commande</v-btn
          >
        </div>
        <v-card outlined>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="orders"
              :items-per-page="5"
              class="elevation-0"
              :footer-props="{
                itemsPerPageText: 'Commandes par page',
              }"
            >
              <template v-slot:body="{ items }">
                <tbody class="text-left">
                  <tr v-for="item in items" :key="item._id">
                    <td>
                      {{ format(parseISO(item.createdAt), "dd.MM.yyyy") }}
                    </td>
                    <td>{{ item.quantity }}</td>
                    <td>{{ orderStatusLabels[item.status] }}</td>
                  </tr>
                </tbody>
              </template>
              <!-- eslint-disable-next-line vue/valid-v-slot justification: possible according to vuetify documentation -->
              <template
                v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }"
                >{{ pageStart }}-{{ pageStop }} de {{ itemsLength }}</template
              >
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import { format, parseISO } from "date-fns";

export default {
  async mounted() {
    const { data: orders } = await axios({
      method: "get",
      url: `${process.env.VUE_APP_API_URI}/orders`,
    });
    this.orders = orders;
  },
  props: ["orderStatusLabels"],
  data: () => ({
    orders: [],
    headers: [
      {
        text: "Date",
        align: "start",
        sortable: false,
        value: "createdAt",
      },
      {
        text: "Quantité",
        align: "start",
        sortable: false,
        value: "quantity",
      },
      {
        text: "Statut",
        align: "start",
        sortable: false,
        value: "status",
      },
    ],
    format,
    parseISO,
  }),
};
</script>
