<template>
  <div>
    <h2>Webmail</h2>
    <div>Après avoir saisi et prévisualisé vos informations</div>
    <ol>
      <li>Se connecter au webmail.</li>
      <li>
        Copiez le contenu de la signature désirée (<a
          href="/signatures?type=internal"
          target="_blank"
          >interne</a
        >
        | <a href="/signatures?type=simple" target="_blank">texte</a>)
      </li>
      <li>
        Coller contenu de la signature e-mail dans le champ prévu à cet effet
        dans le
        <a
          href="https://webmail.heig-vd.ch/owa/#path=/options/mailsignatures"
          target="_blank"
          >webmail</a
        >.
      </li>
      <li>Enregistrer les modifications.</li>
    </ol>
  </div>
</template>

<script>
export default {
  props: ["downloadSignatures"],
};
</script>

<style scoped>
code {
  padding: 2px 4px;
  font-size: 90%;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px;
}
</style>