<template>
  <div>
    <h2>Outlook toutes versions confondues</h2>
    <div>Après avoir saisi et prévisualisé vos informations</div>
    <ol>
      <li>
        Télécharger le fichier
        <a href="#" @click.stop="downloadSignatures">zip</a>.
      </li>
      <li>
        Ouvrir un explorateur windows et utiliser l'adresse
        <code>%appdata%\Microsoft\Signatures</code>.
        <ul>
          <li>
            Si aucune signature n'a été configurée, il se peut que l'explorateur
            affiche une erreur.
          </li>
          <li>
            Dans ce cas, il suffit d'accéder au dossier
            <code>%appdata%\Microsoft\</code> et de créer un nouveau répertoire
            <code>Signatures</code>.
          </li>
        </ul>
      </li>
      <li>
        Décompresser le fichier zip et y placer les fichiers <code>.htm</code>.
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  props: ['downloadSignatures']
};
</script>

<style scoped>
code {
  padding: 2px 4px;
  font-size: 90%;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px;
}
</style>