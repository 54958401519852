<template>
  <v-container class="mt-10" v-if="employee">
    <div class="display-1">Informations</div>
    <v-divider class="my-10"></v-divider>
    <v-card outlined class="px-15 pb-15">
      <v-card-text>
        <v-row class="mt-5">
          <v-col>
            <div
              v-if="!employee.email || !employee.address.city"
              class="red--text"
            >
              <v-icon x-small class="mr-1" color="red"
                >mdi-alert-outline</v-icon
              >
              Certaines informations vous concernant ne sont pas encore à jour
              (voir les champs signalés en rouge dans votre profil). Afin de
              pouvoir installer vos signatures e-mail et commander des cartes de
              visite, veuillez utiliser le formulaire ci-dessous et demander au
              service Ressources Humaines de compléter ces informations dans le
              SIRH.
            </div>
          </v-col>
        </v-row>
        <v-divider class="my-10" v-if="!employee.email || !employee.address.city"></v-divider>
        <v-row>
          <v-col cols="12" sm="4">
            <div class="subtitle-2">NOM</div>
            <div>{{ employee.lastname.toUpperCase() }}</div>
            <div class="mt-5 subtitle-2">PRÉNOM</div>
            <div>{{ employee.firstname }}</div>
          </v-col>
          <v-col cols="12" sm="4">
            <div class="subtitle-2">EMAIL</div>
            <div v-if="employee.email">{{ employee.email }}</div>
            <div v-else class="red--text">
              <v-icon x-small class="mr-1" color="red">mdi-alert-outline</v-icon
              >Adresse e-mail non renseignée.
            </div>
            <div class="mt-5 subtitle-2">TÉLÉPHONE</div>
            <div>
              {{
                employee.phones.find((p) => p.type === "landline")
                  ? employee.phones.find((p) => p.type === "landline").number
                  : "-"
              }}
            </div>
            <div class="mt-5 subtitle-2">MOBILE</div>
            <div>
              {{
                employee.phones.find((p) => p.type === "cellphone")
                  ? employee.phones.find((p) => p.type === "cellphone").number
                  : "-"
              }}
            </div>
            <div class="mt-5 subtitle-2">CENTRAL</div>
            <div>
              {{
                employee.phones.find((p) => p.type === "central")
                  ? employee.phones.find((p) => p.type === "central").number
                  : "-"
              }}
            </div>
          </v-col>
          <v-col cols="12" sm="4">
            <img
              v-if="photo"
              :src="photo"
              :alt="`${employee.firstname} ${employee.lastname}`"
              height="180"
            />
            <v-avatar color="grey" size="100" v-else>
              <span class="white--text headline"
                >{{ employee.firstname.charAt(0)
                }}{{ employee.lastname.charAt(0) }}</span
              >
            </v-avatar>
          </v-col>
        </v-row>
        <v-divider class="my-10"></v-divider>
        <v-row>
          <v-col cols="12" sm="4">
            <div class="subtitle-2">FONCTIONS</div>
            <div v-for="(role, index) in employee.roles" :key="`role-${index}`">
              {{ role.longName }}
            </div>
            <div class="mt-5 subtitle-2">ENTITÉS</div>
            <div
              v-for="(entity, index) in employee.entities"
              :key="`entity-${index}`"
            >
              <span
                v-if="
                  entity.key !== 'HI4_T' ||
                  (entity.key === 'HI4_T' && entity.name === 'CCDR')
                "
                >{{
                  entity.name === "HEE"
                    ? "Health, Engineering & Economics (HEE)"
                    : entity.displayName
                }}</span
              >
            </div>
          </v-col>
          <v-col cols="12" sm="4" v-if="employee.address.street">
            <div class="subtitle-2">SITE</div>
            <div>{{ employee.address.location }}</div>
            <div class="mt-5 subtitle-2">ADRESSE</div>
            <div>
              {{ employee.address.street
              }}<span v-if="employee.address.po">, Case postale</span>
            </div>
            <div>
              CH-{{ employee.address.city.split(",")[0]
              }}{{ employee.address.city.split(",")[1] }}
            </div></v-col
          >
          <v-col cols="12" sm="4" v-else>
            <div class="subtitle-2">SITE</div>

            <div class="red--text">
              <v-icon x-small class="mr-1" color="red">mdi-alert-outline</v-icon
              >Adresse professionnelle non renseignée.
            </div></v-col
          >
          <v-col cols="12" sm="4">
            <div class="subtitle-2">TITRES</div>
            <div v-if="employee.titles.length">
              <div
                v-for="(title, index) in employee.titles"
                :key="`title-${index}`"
              >
                {{ title.longName }}
              </div>
            </div>
            <div v-else>-</div>

            <div class="mt-5 subtitle-2">TITRE INTERNE</div>
            <div>
              {{
                employee.corporateTitle ? employee.corporateTitle.longName : "-"
              }}
            </div>
          </v-col>
        </v-row>
        <v-divider class="my-10"></v-divider>
        <v-row>
          <v-col cols="12" sm="8">
            <div class="mb-5">
              Information sur l’utilisation des cartes de visites et des
              signatures e-mail :
              <a
                href="https://heig.ch/correspondance-note-exp"
                alt="Note explicative"
                target="_blank"
                >note explicative</a
              >.
            </div>
            <div class="mb-5">
              Vos données ne sont pas à jour? Faites-nous parvenir votre demande
              de modifications à l'aide du formulaire ci-dessous.
            </div>
            <v-textarea
              outlined
              dense
              label="Demande de modifications"
              v-model="message"
              color="blue-grey lighten-1"
              :disabled="switchMode"
            ></v-textarea>
            <div>
              <v-btn
                outlined
                block
                @click="submitRequest"
                :disabled="!message || switchMode"
                >Soumettre ma demande</v-btn
              >
            </div></v-col
          >
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  props: ["employee", "photo", "openSnackbar", "switchMode"],
  data: () => ({ message: "" }),
  methods: {
    async submitRequest() {
      try {
        await axios({
          method: "post",
          url: `${process.env.VUE_APP_API_URI}/requests`,
          data: { message: this.message },
        });
        this.message = "";
        this.openSnackbar({
          color: "success",
          message: "Votre demande a été soumise avec succès",
        });
      } catch (error) {
        this.openSnackbar({
          color: "error",
          message: error,
        });
      }
    },
  },
};
</script>
