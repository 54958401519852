<template>
  <v-container class="mt-10">
    <div class="display-1">Installation des signatures</div>
    <v-divider class="my-10"></v-divider>
    <v-row>
      <v-col cols="12" sm="12">
        <v-card outlined>
          <v-tabs v-model="tab" centered icons-and-text class="border-bottom">
            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#tab-windows">
              Windows
              <v-icon>mdi-microsoft-windows</v-icon>
            </v-tab>

            <v-tab href="#tab-macos">
              MacOS
              <v-icon>mdi-apple</v-icon>
            </v-tab>
            <v-tab href="#tab-webmail">
              Webmail
              <v-icon>mdi-email</v-icon>
            </v-tab>
          </v-tabs>
          <v-divider></v-divider>
          <v-tabs-items v-model="tab">
            <v-tab-item value="tab-windows">
              <v-card flat>
                <v-card-text class="pa-15"
                  ><procedure-windows :downloadSignatures="downloadSignatures"
                /></v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item value="tab-macos">
              <v-card flat>
                <v-card-text class="pa-15"
                  ><procedure-mac-os :downloadSignatures="downloadSignatures"
                /></v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item value="tab-webmail">
              <v-card flat>
                <v-card-text class="pa-15"><procedure-webmail /></v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProcedureWindows from "@/components/ProcedureWindows";
import ProcedureMacOs from "@/components/ProcedureMacOs";
import ProcedureWebmail from "@/components/ProcedureWebmail";
import axios from "axios";
import fileDownload from "js-file-download";

export default {
  components: { ProcedureWindows, ProcedureMacOs, ProcedureWebmail },
  data: () => ({
    tab: null,
  }),
  methods: {
    async downloadSignatures() {
      try {
        const { data } = await axios({
          method: "get",
          url: `${process.env.VUE_APP_API_URI}/signatures`,
          responseType: "arraybuffer",
        });
        fileDownload(data, "signatures.zip");
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
