var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-10",attrs:{"outlined":""}},[(_vm.employee)?_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"sm":"6"}},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.employee.firstname)+" "+_vm._s(_vm.employee.lastname)+" ")]),_c('div',[_vm._v("Signature interne")]),_c('div',[_vm._v("À: John Doe")])]),_c('v-col',{staticClass:"text-right",attrs:{"sm":"6"}},[_c('div',[_vm._v(_vm._s(_vm.dateNow))]),_c('v-avatar',{staticClass:"avatar mt-2",attrs:{"color":"grey"}},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.employee.firstname.charAt(0))+_vm._s(_vm.employee.lastname.charAt(0)))])])],1),_c('v-col',{attrs:{"sm":"12"}},[_c('v-divider')],1),_c('v-col',{attrs:{"sm":"12"}},[_c('div',[_vm._v("Bonjour,")]),_c('div',{staticClass:"mt-3"},[_vm._v("Ceci est un exemple de la nouvelle signature.")]),_c('div',{staticClass:"my-3"},[_vm._v("Avec mes meilleures salutations,")]),_c('div',{staticClass:"signEmail"},[_c('div',{staticClass:"name"},[_c('strong',[_vm._v(_vm._s(_vm.employee.firstname)+" "+_vm._s(_vm.employee.lastname))])]),_c('div',{staticClass:"titles"},[_vm._v(" "+_vm._s(_vm.employee.titles .filter(function (t) { return t.config.signatures; }) .map(function (t) { return t.longName; }) .join())+" ")]),(_vm.employee.corporateTitle)?_c('div',[(_vm.employee.corporateTitle.config.signatures)?_c('div',[_vm._v(" "+_vm._s(_vm.employee.corporateTitle.longName)+" ")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"roles"},[_c('pre',[_vm._v(_vm._s(_vm.employee.roles
                .filter(function (r) { return r.config.signatures; })
                .map(function (r) { return _vm.specialRequest.indexOf(r.longName) > -1
                    ? _vm.employee.entities.find(function (e) { return e.level === "Département"; })
                      ? ((r[
                            r.longName !== "Maître d'enseignement"
                              ? r.config.labels.signatures
                              : "longName"
                          ]) + " - " + (_vm.employee.entities.find(
                            function (e) { return e.level === "Département"; }
                          ).level) + " " + (_vm.employee.entities.find(
                            function (e) { return e.level === "Département"; }
                          ).name))
                      : r.longName !== "Maître d'enseignement"
                      ? r[r.config.labels.signatures]
                      : r.longName
                    : _vm.shortNameDisplay.indexOf(r.longName) > -1
                    ? r.shortName
                    : r[r.config.labels.signatures]; }
                )
                .join("\n")))])]),(
              _vm.employee.phones.find(function (p) { return p.type === 'landline'; }) &&
              _vm.employee.phones.find(function (p) { return p.type === 'landline'; }).config
                .signatures
            )?_c('div',{staticClass:"phone"},[_vm._v(" T "+_vm._s(_vm.employee.phones.find(function (p) { return p.type === "landline"; }).number)+" ")]):_vm._e(),(
              _vm.employee.phones.find(function (p) { return p.type === 'cellphone'; }) &&
              _vm.employee.phones.find(function (p) { return p.type === 'cellphone'; }).config
                .signatures
            )?_c('div',{staticClass:"phone"},[_vm._v(" M "+_vm._s(_vm.employee.phones.find(function (p) { return p.type === "cellphone"; }).number)+" ")]):_vm._e(),(
              _vm.employee.phones.find(function (p) { return p.type === 'central'; }) &&
              _vm.employee.phones.find(function (p) { return p.type === 'central'; }).config
                .signatures
            )?_c('div',{staticClass:"phone"},[_vm._v(" C "+_vm._s(_vm.employee.phones.find(function (p) { return p.type === "central"; }).number)+" ")]):_vm._e(),_c('div',{staticClass:"address"},[_c('div',[_vm._v(" "+_vm._s(_vm.employee.address.street)),(_vm.employee.address.po)?_c('span',[_vm._v(", Case postale")]):_vm._e()]),_c('div',[_vm._v(" CH-"+_vm._s(_vm.employee.address.city.split(",")[0])+" "+_vm._s(_vm.employee.address.city.split(",")[1])+" ")])]),_c('div',[_c('a',{staticClass:"link",attrs:{"href":"https://heig-vd.ch"}},[_vm._v("heig-vd.ch")])]),_c('div',{staticClass:"logo-container"},[_c('a',{attrs:{"href":"https://heig-vd.ch"}},[_c('img',{staticClass:"logo",attrs:{"src":_vm.employee.logo.signatures,"alt":"Logo HEIG-VD"}})])])])])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }