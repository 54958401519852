<template>
  <v-container class="mt-10" v-if="employee">
    <div class="display-1">Pages blanches</div>
    <v-divider class="my-10"></v-divider>
    <v-row>
      <v-col cols="12" sm="4">
        <v-card class="pa-5" outlined>
          <v-card-text>
            <div
              class="subtitle-2"
              v-if="
                employee.roles.find(
                  (r) =>
                    specialRequest
                      .filter((s) => s !== 'Maître d\'enseignement')
                      .indexOf(r.longName) > -1
                )
              "
            >
              FONCTIONS
            </div>

            <div v-for="(role, index) in employee.roles" :key="`role-${index}`">
              <v-checkbox
                v-if="
                  specialRequest
                    .filter((s) => s !== 'Maître d\'enseignement')
                    .indexOf(role.longName) > -1
                "
                class="px-3 my-0"
                :label="
                  shortNameDisplay.indexOf(role.longName) > -1
                    ? role.shortName
                    : role.longName
                "
                color="blue-grey lighten-1"
                @click="autosave"
                disabled
                dense
                checked
                v-model="professor"
              ></v-checkbox>
              <v-radio-group
                class="px-10 my-0"
                row
                v-if="
                  specialRequest
                    .filter((s) => s !== 'Maître d\'enseignement')
                    .indexOf(role.longName) > -1
                "
                v-model="employee.professorConfig.whitepages"
                @change="autosave"
              >
                <v-radio
                  label="Libéllé court"
                  color="blue-grey lighten-1"
                  value="shortName"
                ></v-radio>
                <v-radio
                  label="Libéllé long"
                  color="blue-grey lighten-1"
                  value="longName"
                ></v-radio>
              </v-radio-group>
            </div>
            <div
              class="mt-5 subtitle-2"
              v-if="employee.phones.find((p) => p.type === 'cellphone')"
            >
              TÉLÉPHONES
            </div>
            <div
              v-for="(phone, index) in employee.phones"
              :key="`phone-${index}`"
            >
              <v-checkbox
                class="px-3 my-0"
                v-if="phone.type === 'cellphone'"
                :label="phone.number"
                v-model="phone.config.whitepages"
                color="blue-grey lighten-1"
                @click="autosave"
                dense
              ></v-checkbox>
            </div>
            <div class="subtitle-2 mt-5">PHOTO</div>
            <v-checkbox
              class="my-0 px-3"
              label="Afficher ma photo"
              v-model="employee.displayPicture"
              color="blue-grey lighten-1"
              @click="autosave"
              dense
            ></v-checkbox>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="8">
        <v-card outlined>
          <v-card-text class="pa-15"
            ><div class="text-center">
              <img
                :src="photo"
                :alt="`${employee.firstname} ${employee.lastname}`"
                height="180"
                v-if="employee.displayPicture"
              />
              <v-avatar color="grey" size="100" v-else>
                <span class="white--text headline"
                  >{{ employee.firstname.charAt(0)
                  }}{{ employee.lastname.charAt(0) }}</span
                >
              </v-avatar>
              <div class="headline mt-3">
                {{ employee.firstname }} {{ employee.lastname }}
              </div>
              <div
                class="body-1"
                v-for="(entity, index) in employee.entities"
                :key="`entity-${index}`"
              >
                {{ entity.displayName }}
              </div>
              <div>E-mail: {{ employee.email }}</div>
              <div v-if="employee.phones.find((p) => p.type === 'landline')">
                Prof.:
                {{ employee.phones.find((p) => p.type === "landline").number }}
              </div>
              <div
                v-if="
                  employee.phones.find((p) => p.type === 'cellphone') &&
                  employee.phones.find((p) => p.type === 'cellphone').config
                    .whitepages
                "
              >
                Mob.:
                {{ employee.phones.find((p) => p.type === "cellphone").number }}
              </div>
              <v-divider class="my-5"></v-divider>
              <div
                v-for="(role, index) in employee.roles"
                :key="`role-${index}`"
              >
                <div v-if="specialRequest.indexOf(role.longName) > -1">
                  {{
                    role[
                      employee.professorConfig &&
                      role.longName !== "Maître d'enseignement"
                        ? employee.professorConfig.whitepages
                        : "longName"
                    ]
                  }}
                </div>
                <div v-if="specialRequest.indexOf(role.longName) === -1">
                  {{
                    shortNameDisplay.indexOf(role.longName) > -1
                      ? role.shortName
                      : role.longName
                  }}
                </div>
              </div>
              <v-divider class="my-5"></v-divider>
              <div>Site de {{ employee.address.location }}</div>
              <div>{{ employee.address.street }}</div>
              <div>{{ employee.address.city }}</div>
            </div></v-card-text
          >
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: [
    "employee",
    "autosave",
    "photo",
    "switchMode",
    "shortNameDisplay",
    "specialRequest",
  ],
  data: () => ({ professor: true }),
};
</script>
