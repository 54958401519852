<template>
  <v-card class="pa-10 mt-10" outlined>
    <v-card-text v-if="employee">
      <v-row>
        <v-col sm="6">
          <div class="font-weight-bold">
            {{ employee.firstname }} {{ employee.lastname }}
          </div>
          <div>Signature externe</div>
          <div>À: John Doe</div>
        </v-col>
        <v-col sm="6" class="text-right">
          <div>{{ dateNow }}</div>
          <v-avatar color="grey" class="avatar mt-2">
            <span class="white--text"
              >{{ employee.firstname.charAt(0)
              }}{{ employee.lastname.charAt(0) }}</span
            >
          </v-avatar>
        </v-col>
        <v-col sm="12">
          <v-divider></v-divider>
        </v-col>
        <v-col sm="12">
          <div>Bonjour,</div>
          <div class="mt-3">
            Ceci est un exemple de signature en texte brut.
          </div>
          <div class="my-3">Avec mes meilleures salutations,</div>
          <div>===================================</div>
          <div>{{ employee.firstname.toUpperCase() }} {{ employee.lastname.toUpperCase() }}</div>
          <div>
            {{
              employee.titles
                .filter((t) => t.config.signatures)
                .map((t) => t.longName)
                .join()
            }}
          </div>
          <div v-if="employee.corporateTitle">
            <div v-if="employee.corporateTitle.config.signatures">
              {{ employee.corporateTitle.longName }}
            </div>
          </div>
          <div>
            <pre class="pre">{{
              employee.roles
                .filter((r) => r.config.signatures)
                .map((r) =>
                  specialRequest.indexOf(r.longName) > -1
                    ? employee.entities.find((e) => e.level === "Département")
                      ? `${
                          r[
                            r.longName !== "Maître d'enseignement"
                              ? r.config.labels.signatures
                              : "longName"
                          ]
                        } - ${
                          employee.entities.find(
                            (e) => e.level === "Département"
                          ).level
                        } ${
                          employee.entities.find(
                            (e) => e.level === "Département"
                          ).name
                        }`
                      : r.longName !== "Maître d'enseignement"
                      ? r[r.config.labels.signatures]
                      : r.longName
                    : shortNameDisplay.indexOf(r.longName) > -1
                    ? r.shortName
                    : r[r.config.labels.signatures]
                )
                .join("\n")
            }}</pre>
          </div>
          <div
            v-if="
              employee.phones.find((p) => p.type === 'landline') &&
              employee.phones.find((p) => p.type === 'landline').config
                .signatures
            "
          >
            T
            {{
              employee.phones.find((p) => p.type === "landline") &&
              employee.phones.find((p) => p.type === "landline").config
                .signatures
                ? employee.phones.find((p) => p.type === "landline").number
                : ""
            }}
          </div>

          <div
            v-if="
              employee.phones.find((p) => p.type === 'cellphone') &&
              employee.phones.find((p) => p.type === 'cellphone').config
                .signatures
            "
          >
            M
            {{
              employee.phones.find((p) => p.type === "cellphone") &&
              employee.phones.find((p) => p.type === "cellphone").config
                .signatures
                ? employee.phones.find((p) => p.type === "cellphone").number
                : ""
            }}
          </div>
          <div
            v-if="
              employee.phones.find((p) => p.type === 'central') &&
              employee.phones.find((p) => p.type === 'central').config
                .signatures
            "
          >
            C
            {{
              employee.phones.find((p) => p.type === "central") &&
              employee.phones.find((p) => p.type === "central").config
                .signatures
                ? employee.phones.find((p) => p.type === "central").number
                : ""
            }}
          </div>
          <div>
            HEIG-VD, {{ employee.address.street
            }}<span v-if="employee.address.po">, Case postale</span>
          </div>
          <div>
            CH-{{ employee.address.city.split(",")[0] }}
            {{ employee.address.city.split(",")[1] }}
          </div>

          <div>===================================</div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { format } from "date-fns";
import frLocale from "date-fns/locale/fr";

export default {
  props: ["employee", "specialRequest", "shortNameDisplay"],
  computed: {
    dateNow: function () {
      return format(new Date(), "dd MMMM yyyy à HH:mm", { locale: frLocale });
    },
  },
};
</script>

<style scoped>
.pre {
  font-family: unset;
}
</style>