<template>
  <div class="text-center">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="isAdmin ? 500 : 300"
      offset-y
    >
      <template v-slot:activator="{ on }">
        <v-btn small v-on="on" fab text class="ma-0" :ripple="false">
          <v-icon>mdi-account-circle</v-icon>
        </v-btn>
      </template>
      <v-card v-if="currentUser">
        <v-list>
          <v-list-item>
            <v-list-item-icon small>
              <v-avatar color="grey" size="48">
                <span class="white--text subtitle">{{ shortName }}</span>
              </v-avatar>
            </v-list-item-icon>
            <v-list-item-content>{{ currentUser.name }}</v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list v-if="isAdmin">
          <v-list-item>
            <v-list-item-content>
              <v-file-input
                outlined
                dense
                class="mt-5"
                @change="setLocalEmployees"
                label="Selectionner une source de données"
                color="blue-grey lighten-1"
              ></v-file-input>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider v-if="isAdmin"></v-divider>
        <v-list v-if="isAdmin">
          <v-list-item>
            <v-list-item-icon small>
              <v-avatar size="48" class="mt-5">
                <v-icon>mdi-account-switch-outline</v-icon>
              </v-avatar>
            </v-list-item-icon>
            <v-list-item-content
              ><v-autocomplete
                outlined
                dense
                class="mt-6"
                :items="localEmployees.length ? localEmployees : employees"
                clearable
                @change="switchEmployee"
                label="Afficher les informations d'un collègue"
                color="blue-grey lighten-1"
              ></v-autocomplete
            ></v-list-item-content>
          </v-list-item>
          <v-list-item>
            <div class="caption">
              Source de la liste des contacts :
              {{ localEmployees.length ? "Fichier XLSX" : "base de données" }}
            </div>
          </v-list-item>
        </v-list>
        <v-divider v-if="isAdmin"></v-divider>
        <v-list>
          <v-list-item @click="this.$keycloak.logoutFn">
            <v-list-item-content>Se déconnecter</v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import axios from "axios";
import XLSX from "xlsx";

export default {
  async mounted() {
    this.currentUser = this.$keycloak.tokenParsed;
    this.isAdmin = this.$keycloak.hasResourceRole("admin-colleagues", "correspondance");
    if (this.isAdmin) {
      const { data: employees } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/admin/employees`,
      });
      this.employees = employees;
    }
  },
  computed: {
    shortName: function () {
      return `${this.$keycloak.tokenParsed.given_name.substr(
        0,
        1
      )}${this.$keycloak.tokenParsed.family_name.substr(0, 1)}`;
    },
  },
  props: ["setEmployee", "setLocalEmployee", "resetEmployee"],
  data: () => ({
    menu: false,
    currentUser: undefined,
    isAdmin: false,
    employees: [],
    localEmployees: [],
  }),
  methods: {
    readFileAsync: function (file) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = function (e) {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          resolve(workbook);
        };
        reader.readAsArrayBuffer(file);
      });
    },
    switchEmployee(selected) {
      if (selected) {
        if (typeof selected === "object") {
          this.setLocalEmployee(selected);
        } else {
          this.setEmployee(selected);
        }
      } else {
        this.resetEmployee();
      }
    },
    async setLocalEmployees(file) {
      if (!file) {
        this.localEmployees = [];
        this.resetEmployee();
      } else {
        const wb = await this.readFileAsync(file);
        const ws = wb.Sheets[wb.SheetNames[0]];
        const employees = XLSX.utils.sheet_to_json(ws);
        const localEmployees = employees.map((e) => {
          const employee = {
            id: e["Matricule"],
            idisa: e["Matricule (ISA)"],
            idips: e["Matricule (PS)"],
            lastname: e["Nom"],
            firstname: e["Prénom"],
            email: e["Email"],
            gender: e["Sexe"],
            address: {
              location: e["Site"],
              street: e["Adresse du site"],
              po: e["Site (case postale)"],
              city: e["Site (Lieu)"],
            },
            phones: [
              e["Tél Fixe/Direct"]
                ? {
                    number: e["Tél Fixe/Direct"],
                    type: "landline",
                    config: { signatures: true, cards: true },
                  }
                : undefined,
              e["tel 2 - Label"]
                ? {
                    number: e["tel 2 - Label"],
                    type: "cellphone",
                    config: { signatures: false, cards: false },
                  }
                : undefined,
            ].filter((p) => p),
            roles: [
              e["Fonction 1 HEIG-VD (libellé long)"]
                ? {
                    longName: e["Fonction 1 HEIG-VD (libellé long)"],
                    shortName: e["Fonction 1 HEIG-VD (Libellé court)"],
                    config: {
                      signatures: true,
                      cards: true,
                    },
                  }
                : undefined,
              e["Fonction / rôle 2 (Libellé long)"]
                ? {
                    longName: e["Fonction / rôle 2 (Libellé long)"],
                    shortName: e["Fonction / rôle 2 (Libellé court)"],
                    config: {
                      signatures: false,
                      cards: false,
                    },
                  }
                : undefined,
              e["Fonction / rôle 3 (Libellé long)"]
                ? {
                    longName: e["Fonction / rôle 3 (Libellé long)"],
                    shortName: e["Fonction / rôle 3 (Libellé court)"],
                    config: {
                      signatures: false,
                      cards: false,
                    },
                  }
                : undefined,
              e["Fonction / rôle 4 (Libellé long)"]
                ? {
                    longName: e["Fonction / rôle 4 (Libellé long)"],
                    shortName: e["Fonction / rôle 4 (Libellé court)"],
                    config: {
                      signatures: false,
                      cards: false,
                    },
                  }
                : undefined,
              e["Fonction / rôle 5 (Libellé long)"]
                ? {
                    longName: e["Fonction / rôle 5 (Libellé long)"],
                    shortName: e["Fonction / rôle 5 (Libellé court)"],
                    config: {
                      signatures: false,
                      cards: false,
                    },
                  }
                : undefined,
              e["Fonction / rôle 6 (Libellé long)"]
                ? {
                    longName: e["Fonction / rôle 6 (Libellé long)"],
                    shortName: e["Fonction / rôle 6 (Libellé court)"],
                    config: {
                      signatures: false,
                      cards: false,
                    },
                  }
                : undefined,
              e["Fonction / Libellé personnalisé"]
                ? {
                    longName: e["Fonction / Libellé personnalisé"],
                    shortName: e["Fonction / Libellé personnalisé"],
                    config: {
                      signatures: false,
                      cards: false,
                    },
                  }
                : undefined,
            ].filter((r) => r),
            titles: [
              e["Titre (fr)"]
                ? {
                    longName: e["Titre (fr)"],
                    config: { signatures: false, cards: false },
                  }
                : undefined,
              e["Titre (en)"]
                ? {
                    longName: e["Titre (en)"],
                    config: { signatures: false, cards: false },
                  }
                : undefined,
            ].filter((t) => t),
            corporateTitle: e["Titre"],
            entities: [
              e["HI1 (Niveau)"]
                ? {
                    level: e["HI1 (Niveau)"],
                    name: e["HI1"],
                    displayName: e["HI1 (Cartes de visite)"],
                    web: e["WEB1"],
                    config: { signatures: false, cards: false },
                  }
                : undefined,
              e["HI1 (Niveau)"]
                ? {
                    level: e["HI2 (niveau)"],
                    name: e["HI2"],
                    displayName: e["HI2 (cartes de visite)"],
                    web: e["WEB2"],
                    config: { signatures: false, cards: false },
                  }
                : undefined,
              e["HI3 (niveau)"]
                ? {
                    level: e["HI3 (niveau)"],
                    name: e["HI3"],
                    displayName: e["HI3 (cartes de visite)"],
                    web: e["WEB3"],
                    config: { signatures: false, cards: false },
                  }
                : undefined,
            ]
              .filter((e) => e)
              .filter((e) => e.level),
          };

          if (employee.entities.length > 1) {
            employee.entities.shift();
          }

          if (employee.entities[0]) {
            employee.entities[0].config = { signatures: true, cards: true };
          }

          employee.createdAt = employee.updatedAt = new Date();
          employee.displayPicture = false;

          return {
            text: `${employee.firstname} ${employee.lastname}`,
            value: employee,
          };
        });
        this.localEmployees = localEmployees.filter(
          (le) => le.value.email !== this.currentUser.email
        );
      }
    },
  },
};
</script>