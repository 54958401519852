<template>
  <div v-html="signature">signature</div>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    signature: null,
  }),
  async mounted() {
    try {
      const { data } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/signatures?format=html&type=${this.$route.query.type}`,
      });
      this.signature = data
    } catch (error) {
      console.log(error);
    }
  },
};
</script>