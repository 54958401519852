<template>
  <v-container class="mt-10" v-if="employee">
    <div class="display-1">Cartes de visite</div>
    <v-divider class="my-10"></v-divider>

    <v-row>
      <v-col cols="12" sm="4">
        <v-card class="pa-5" outlined>
          <v-card-text>
            <div
              v-if="
                employee.entities.find((e) => e.level === 'Institut') ||
                employee.entities.find((e) => e.level === 'Centre')
              "
            >
              <div class="mt-5 subtitle-2">LOGOS</div>
              <v-radio-group
                v-model="employee.logo.cards"
                row
                @change="autosave"
              >
                <v-radio
                  label="École"
                  value="https://storage.googleapis.com/visual-identity/logo/2020-slim.svg"
                  color="blue-grey lighten-1"
                ></v-radio>
                <v-radio
                  label="Institut"
                  :value="`https://storage.googleapis.com/visual-identity/logo/institutes/${dashify(
                    employee.entities.find((e) => e.level === 'Institut').name
                  )}.svg`"
                  v-if="employee.entities.find((e) => e.level === 'Institut')"
                  color="blue-grey lighten-1"
                ></v-radio>
                <v-radio
                  label="Centre"
                  :value="`https://storage.googleapis.com/visual-identity/logo/centers/${dashify(
                    employee.entities.find((e) => e.level === 'Centre').name
                  )}.svg`"
                  v-if="employee.entities.find((e) => e.level === 'Centre')"
                  color="blue-grey lighten-1"
                ></v-radio>
                <v-radio
                  label="Centre"
                  :value="`https://storage.googleapis.com/visual-identity/logo/groups/${dashify(
                    employee.entities.find((e) => e.name === 'CCDR').name
                  )}.svg`"
                  v-if="employee.entities.find((e) => e.name === 'CCDR')"
                  color="blue-grey lighten-1"
                ></v-radio>
                <v-radio
                  label="GTC"
                  :value="`https://storage.googleapis.com/visual-identity/logo/groups/${employee.entities
                    .find((e) => e.key === 'VHOUJ_Custom_Info_5')
                    .name.toLowerCase()}.svg`"
                  v-if="
                    employee.entities.find(
                      (e) => e.key === 'VHOUJ_Custom_Info_5'
                    )
                  "
                  color="blue-grey lighten-1"
                ></v-radio>
              </v-radio-group>
            </div>
            <div v-if="employee.titles.length">
              <div class="subtitle-2">TITRES</div>
              <v-checkbox
                class="px-3 my-0"
                v-for="(title, index) in employee.titles"
                :key="`title-${index}`"
                :label="title.longName"
                v-model="title.config.cards"
                color="blue-grey lighten-1"
                @click="autosave"
                dense
              ></v-checkbox>
            </div>
            <div v-if="employee.corporateTitle">
              <div class="subtitle-2">TITRE INTERNE</div>
              <v-checkbox
                class="px-3 my-0"
                :label="employee.corporateTitle.longName"
                v-model="employee.corporateTitle.config.cards"
                color="blue-grey lighten-1"
                @click="autosave"
                dense
              ></v-checkbox>
            </div>
            <div class="subtitle-2">FONCTIONS</div>
            <div v-for="(role, index) in employee.roles" :key="`role-${index}`">
              <v-checkbox
                class="px-3 my-0"
                :label="
                  shortNameDisplay.indexOf(role.longName) > -1
                    ? role.shortName
                    : role.longName
                "
                v-model="role.config.cards"
                color="blue-grey lighten-1"
                @click="autosave"
                :disabled="
                  role.config.cards &&
                  employee.roles.filter((r) => r.config.cards).length === 1
                "
                dense
              ></v-checkbox>
              <v-radio-group
                class="px-10 my-0"
                row
                v-if="
                  role.longName &&
                  role.shortName &&
                  role.longName !== role.shortName &&
                  role.shortName !== '-' &&
                  shortNameDisplay.indexOf(role.longName) === -1
                "
                v-model="role.config.labels.cards"
                @change="autosave"
                :disabled="!role.config.cards"
              >
                <v-radio
                  label="Libéllé court"
                  color="blue-grey lighten-1"
                  value="shortName"
                ></v-radio>
                <v-radio
                  label="Libéllé long"
                  color="blue-grey lighten-1"
                  value="longName"
                ></v-radio>
              </v-radio-group>
            </div>
            <div class="mt-5 subtitle-2">TÉLÉPHONES</div>
            <v-checkbox
              class="px-3 my-0"
              v-for="(phone, index) in employee.phones"
              :key="`phone-${index}`"
              :label="phone.number"
              v-model="phone.config.cards"
              color="blue-grey lighten-1"
              @click="autosave"
              dense
            ></v-checkbox>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="8">
        <v-card outlined>
          <v-card-title class="justify-center"
            ><card-front
              :employee="employee"
              :specialRequest="specialRequest"
              :shortNameDisplay="shortNameDisplay" /><card-back
              :employee="employee"
          /></v-card-title>
        </v-card>
        <v-btn
          block
          outlined
          class="mt-5"
          @click="dialog = true"
          :disabled="switchMode"
          v-if="employee.email && employee.address.street"
          >Commander des cartes de visite</v-btn
        >
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-3">
          Commande de cartes de visite
        </v-card-title>

        <v-card-text class="mt-5">
          <div class="mt-3">
            Une fois votre commande validée, vos cartes de visite vous
            parviendront directement en courier interne.
          </div>
          <v-select
            outlined
            dense
            :items="quantities"
            v-model="quantity"
            class="mt-5"
            label="Quantité"
            :disabled="true"
          ></v-select>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="submitOrder" :disabled="!quantity || loading"
            >Commander
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import CardFront from "@/components/CardFront";
import CardBack from "@/components/CardBack";
import dashify from "dashify";

export default {
  props: [
    "employee",
    "autosave",
    "openSnackbar",
    "switchMode",
    "specialRequest",
    "shortNameDisplay",
  ],
  data: () => ({
    dialog: false,
    quantity: 90,
    quantities: [90],
    loading: false,
  }),
  components: { CardFront, CardBack },
  methods: {
    dashify,
    async submitOrder() {
      this.loading = true;
      try {
        await axios({
          method: "post",
          url: `${process.env.VUE_APP_API_URI}/orders`,
          data: { config: this.employee, quantity: this.quantity },
        });
        this.dialog = false;
        this.loading = false;
        this.quantity = undefined;
        this.openSnackbar({
          color: "success",
          message: "Votre commande a été soumise avec succès",
        });
      } catch (error) {
        this.dialog = false;
        this.loading = false;
        this.openSnackbar({
          color: "error",
          message: error,
        });
      }
    },
  },
};
</script>
